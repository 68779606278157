import React from "react"
import { FaDatabase, FaListUl, FaCheck, FaTrash, FaCheckCircle, FaFile , FaMagic, FaProjectDiagram} from "react-icons/fa"
import { useDataNavbarContext } from "../context/DataNavbarContext"
const user = JSON.parse(sessionStorage.getItem('user'))
let size = 15

const Metricas = ({ badgeText }) => {
  let text = ""
  const navbarCtx = useDataNavbarContext()

  switch (badgeText) {
    case 'tipify-calls-1':
      text = navbarCtx?.totalTipifyIngles
      break;
    case 'tipify-calls-2':
      text = navbarCtx?.totalTipifyMayor65
      break;
    case 'tipify-calls-3':
      text = navbarCtx?.totalTipifySeguroTrabajo
      break;
    case 'tipify-calls-4':
      text = navbarCtx?.totalTipifyMedicare
      break;
    case 'tipify-calls-5':
      text = navbarCtx?.totalTipifyMedicate
      break;
    case 'tipify-calls-6':
      text = navbarCtx?.totalTipifyColgo
      break;
    case 'tipify-calls-7':
      text = navbarCtx?.totalTipifyNoContesto
      break;
    case 'tipify-calls-8':
      text = navbarCtx?.totalTipifyBuzon
      break;
    case 'tipify-calls-9':
      text = navbarCtx?.totalTipifyCuentaConAgente
      break;
    case 'tipify-calls-10':
      text = navbarCtx?.totalTipifyNoEstaInteresado
      break;
    default:
      text = "Pendiente"
      break;
  }

  return (
    <div className="menu-badge">
      {text}
    </div>
  )
}

const navigationSuperadminTop = [
  /* {
    id: "home",
    title: "Homepage",
    type: "item",
    icon: <FaHome size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  }, */
  {
    id: "verify-number",
    title: "Verify number",
    type: "item",
    icon: <FaCheckCircle size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/verify-number"
  },
  {
    id: "create-number-project",
    title: "Create number project",
    type: "item",
    icon: <FaProjectDiagram size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/create-number-project"
  },
  {
    id: "create-number-file",
    title: "Create number file",
    type: "item",
    icon: <FaFile size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/create-number-file"
  },
  {
    id: "create-number-state-file",
    title: "Create number state file",
    type: "item",
    icon: <FaMagic size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/create-number-state-file"
  },
  {
    id: "tipify-calls",
    title: "Tipify Calls",
    type: "collapse",
    icon: <FaListUl size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "tipify-calls-1",
        title: "Habla inglés",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-1" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-1"
      },
      {
        id: "tipify-calls-2",
        title: "Mayor de 65 años",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-2" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-2"
      },
      {
        id: "tipify-calls-3",
        title: "Cuenta con seguro de trabajo",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-3" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-3"
      },
      {
        id: "tipify-calls-4",
        title: "Medicare",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-4" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-4"
      },
      {
        id: "tipify-calls-5",
        title: "Medicate",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-5" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-5"
      },
      {
        id: "tipify-calls-6",
        title: "Colgó",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-6" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-6"
      },
      {
        id: "tipify-calls-7",
        title: "No contesto",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-7" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-7"
      },
      {
        id: "tipify-calls-8",
        title: "Buzón",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-8" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-8"
      },
      {
        id: "tipify-calls-9",
        title: "Cuenta con agente",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-9" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-9"
      },
      {
        id: "tipify-calls-10",
        title: "No está interesado",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-10" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-10"
      },
    ]
  },
  {
    id: "trash-number",
    title: "Trash",
    type: "item",
    icon: <FaTrash size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/trash-number"
  },
  {
    id: "database-backup",
    title: "Database backup",
    type: "item",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/database-backup"
  },
  /* {
    id: "formuser",
    title: "Users",
    type: "collapse",
    icon: <FaUserCog size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formuser1",
        title: "User Form",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/userform"
      },
    ]
  }, */
]

const navigationSuperadmin = [
  /* {
    id: "home",
    title: "Homepage",
    type: "item",
    icon: <FaHome size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  }, */
  {
    id: "verify-number",
    title: "Verify number",
    type: "item",
    icon: <FaCheckCircle size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/verify-number"
  },
  {
    id: "create-number-project",
    title: "Create number project",
    type: "item",
    icon: <FaProjectDiagram size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/create-number-project"
  },
  {
    id: "create-number-file",
    title: "Create number file",
    type: "item",
    icon: <FaFile size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/create-number-file"
  },
  {
    id: "create-number-state-file",
    title: "Create number state file",
    type: "item",
    icon: <FaMagic size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/create-number-state-file"
  },
  {
    id: "tipify-calls",
    title: "Tipify Calls",
    type: "collapse",
    icon: <FaListUl size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "tipify-calls-1",
        title: "Habla inglés",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-1" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-1"
      },
      {
        id: "tipify-calls-2",
        title: "Mayor de 65 años",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-2" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-2"
      },
      {
        id: "tipify-calls-3",
        title: "Cuenta con seguro de trabajo",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-3" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-3"
      },
      {
        id: "tipify-calls-4",
        title: "Medicare",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-4" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-4"
      },
      {
        id: "tipify-calls-5",
        title: "Medicate",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-5" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-5"
      },
      {
        id: "tipify-calls-6",
        title: "Colgó",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-6" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-6"
      },
      {
        id: "tipify-calls-7",
        title: "No contesto",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-7" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-7"
      },
      {
        id: "tipify-calls-8",
        title: "Buzón",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-8" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-8"
      },
      {
        id: "tipify-calls-9",
        title: "Cuenta con agente",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-9" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-9"
      },
      {
        id: "tipify-calls-10",
        title: "No está interesado",
        type: "item",
        badge: "primary",
        badgeText: <Metricas badgeText="tipify-calls-10" />,
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/tipify-calls-10"
      },
    ]
  },
  {
    id: "trash-number",
    title: "Trash",
    type: "item",
    icon: <FaTrash size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/trash-number"
  },
  {
    id: "database-backup",
    title: "Database backup",
    type: "item",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/database-backup"
  },
  /* {
    id: "formuser",
    title: "Users",
    type: "collapse",
    icon: <FaUserCog size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formuser1",
        title: "User Form",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/userform"
      },
    ]
  }, */
]

const navigationConfig =
  user?.profile === 1 ? navigationSuperadminTop ://SuperadminTop
  user?.profile === 2 ? navigationSuperadmin ://SuperadminTop
  []

export default navigationConfig
